import React, { useState } from 'react';
import {
    Box,
    TextField,
    Button,
    CircularProgress,
    Paper,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const analyzeCompany = async (companyName) => {
    try {
        const response = await axios.post(`${API_URL}/api/analyze`, {
            companyName
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error analyzing company:', error);
        throw error;
    }
};


function formatMarkdown(text) {
    if (!text) return null;

    // Split text into sections by double newlines
    const sections = text.split(/\n\n+/);
    
    return sections.map((section, index) => {
        // Handle sections that start with ###
        if (section.trim().startsWith('###')) {
            const [title, ...content] = section.split('\n');
            const cleanTitle = title.replace(/^###\s*/, '');
            const cleanContent = content.join('\n');
            
            return (
                <Box key={index} sx={{ mb: 4 }}>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            mb: 2,
                            color: 'primary.main',
                            fontWeight: 600,
                            borderBottom: '1px solid',
                            borderColor: 'primary.light',
                            pb: 1
                        }}
                    >
                        {cleanTitle}
                    </Typography>
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                        pl: 2
                    }}>
                        {cleanContent.split('\n').filter(line => line.trim()).map((line, i) => {
                            // Remove bullet point if it exists and clean up the line
                            const cleanLine = line.trim().replace(/^[•-]\s*/, '');
                            // Process bold text without showing ** markers
                            const processedLine = cleanLine.replace(/\*\*(.*?)\*\*/g, (_, p1) => `<strong>${p1}</strong>`);
                            
                            return (
                                <Typography 
                                    key={i} 
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        '& strong': {
                                            color: 'primary.main',
                                            fontWeight: 600,
                                            minWidth: '180px',
                                            mr: 2,
                                            display: 'inline-block'
                                        }
                                    }}
                                >
                                    <span style={{ marginRight: '8px', color: '#ff6b35' }}>•</span>
                                    <span dangerouslySetInnerHTML={{ __html: processedLine }} />
                                </Typography>
                            );
                        })}
                    </Box>
                </Box>
            );
        }

        // Handle regular paragraphs with bold text
        const processedSection = section.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        
        return (
            <Typography 
                key={index}
                paragraph
                sx={{
                    mb: 2,
                    '& strong': { 
                        color: 'primary.main',
                        fontWeight: 600,
                        mr: 1
                    }
                }}
                dangerouslySetInnerHTML={{ __html: processedSection }}
            />
        );
    });
}

function CompanyAnalysis() {
    const [companyName, setCompanyName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [results, setResults] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResults(null);
        setLoading(true);
        setError(null);

        try {
            console.log('Analyzing company:', companyName);
            const response = await axios.post(`${API_URL}/api/analyze`, {
                companyName
            });
            console.log('Received response:', response.data);
            setResults(response.data);
        } catch (err) {
            console.error('Error during analysis:', err);
            setError(err.response?.data?.error || 'An error occurred during analysis');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ py: 4 }}>
            <Paper sx={{ p: 3, mb: 3 }}>
                <Box sx={{ 
                    display: 'flex', 
                    gap: 2,
                    alignItems: 'center',
                }}>
                    <TextField
                        fullWidth
                        label="Why use AI at company..."
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        disabled={loading}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '8px 14px',
                                height: '24px',
                                lineHeight: '24px'
                            },
                            '& .MuiInputLabel-outlined': {
                                transform: 'translate(14px, 8px) scale(1)',
                                '&.Mui-focused, &.MuiFormLabel-filled': {
                                    transform: 'translate(14px, -9px) scale(0.75)'
                                }
                            }
                        }}
                    />
                    <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '140px',
                        height: '40px',
                    }}>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={!companyName}
                                onClick={handleSubmit}
                                sx={{
                                    height: '40px',
                                    minWidth: '120px',
                                    whiteSpace: 'normal',
                                    lineHeight: 1.2,
                                    padding: '8px 16px',
                                    textAlign: 'center'
                                }}
                            >
                                Let's Find Out
                            </Button>
                        )}
                    </Box>
                </Box>
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
            </Paper>

            {results && (
                <Box>
                    <Accordion defaultExpanded>
                        <AccordionSummary 
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                backgroundColor: '#1a1a1a',
                                color: 'white',
                                '& .MuiSvgIcon-root': {
                                    color: 'white'
                                }
                            }}
                        >
                            <Typography variant="h6">Company X-Ray</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ p: 2 }}>
                                {formatMarkdown(results.companyInfo)}
                                {formatMarkdown(results.strategicPriorities)}
                                {formatMarkdown(results.aiProjects)}
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary 
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'white',
                                '& .MuiSvgIcon-root': {
                                    color: 'white'
                                }
                            }}
                        >
                            <Typography variant="h6">
                                Why Use AI at {companyName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ p: 2 }}>
                                {formatMarkdown(results.recommendations)}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
        </Box>
    );
}

export default CompanyAnalysis; 